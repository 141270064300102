// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    // apiKey: "AIzaSyAXXUbOlZK9n2enQeGIEc6ZsqxKs7327uc",
    // authDomain: "karmometerdev.firebaseapp.com",
    // databaseURL: "https://karmometerdev-default-rtdb.firebaseio.com",
    // projectId: "karmometerdev",
    // storageBucket: "karmometerdev.appspot.com",
    // messagingSenderId: "713824172697",
    // appId: "1:713824172697:web:e894cb65ea004d99fa1608",
    // measurementId: "G-BL0W16YLMQ"

  apiKey: "AIzaSyAH4G3hSN12CkHxpPl9TrN5OkUiryyw0mM",
  authDomain: "karmometer-a75b7.firebaseapp.com",
  databaseURL: "https://karmometer-a75b7-default-rtdb.firebaseio.com",
  projectId: "karmometer-a75b7",
  storageBucket: "karmometer-a75b7.appspot.com",
  messagingSenderId: "415330956219",
  appId: "1:415330956219:web:a7047e353508a6f97f8a9e",
  measurementId: "G-PEP1LLTG12"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);