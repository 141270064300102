import { Autoplay,Pagination, Navigation, EffectCreative } from 'swiper/modules';
import { Box, Button, Container, Typography } from '@mui/material'
import { collection, getDocs, doc, getDoc } from "firebase/firestore"
import { Swiper, SwiperSlide } from 'swiper/react';
import Dialogbox from './Dialogbox/Dialogbox'
import { useEffect, useState } from 'react'
import AdsComponent from './Advertisement'
import ig from '../images/ig.png'
import yt from '../images/yt.png'
import tt from '../images/tt.png'
import fb from '../images/fb.png'
import tg from '../images/tg.png'
import { db } from '../firebase'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';


function WebImages() {
  const coll = doc(db, "app_controller_v2/web_home_controller");
  const [data, setData] = useState([]);
  const [text, setText] = useState([]);
  const [open,setOpen] = useState(false);

  const Smdata = [{ mi: ig, href : "https://www.instagram.com/karmometer/" }, { mi: yt, href : "https://www.youtube.com/@karmometer" }, { mi: tt, href : "https://www.tiktok.com/@karmometer_2022" }, { mi: tg, href : "https://t.me/karmometer" }, { mi: fb, href : "https://www.facebook.com/karmometer" },];

  const handleClick1 = () => {
    setOpen(true);
  }
  const handleClose1 = () => {
    setOpen(false);
  }

  const getData = async () => {
    try {
      const docSnapshot = await getDoc(coll);
      const newData = docSnapshot.data();
      setData(newData.banner);
      setText(newData.desc);
    } catch (error) {
      console.error("Error getting data from Firebase:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container sx={{ maxWidth: { xs: 'xs', sm: 'sm' } }} >
      <Box sx={{ mt: 13 }} style={{
        borderRadius: '20px', // Border radius for the slider container
        overflow: 'hidden',
      }}>
        <Swiper
          loop={true}
          autoplay={
            { delay: 4000,}
          }
          pagination={true} 
          className="mySwiper"
          modules={[Autoplay,Pagination, Navigation, EffectCreative]}
          style={{}}
        >
          {data?.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <Box
                  sx={{ justifyContent: 'center', alignItems: "center", display: "flex" }}
                  style={{ borderRadius: '20px' }}
                >
                  <ImageView media={item} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Box sx={{ my: { xs: 6, sm: 4, lg: 6 } }}>
        <TextView value={text} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} onClick={handleClick1}>
        <Button
          sx={{
            backgroundColor: '#008C8C',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            fontWeight: '700',
            width: { xs: '100%', sm: '100%' },
            fontSize: { xs: '20px', sm: '18px' },
            fontFamily: 'Outfit',
            cursor: 'pointer',
            borderRadius: '10px',
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: '#008880',
              color: '#fff',
              fontWeight: '700',
            },
          }}>Start</Button>
      </Box>
      <Dialogbox opens={open} onClose={handleClose1}/>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: { xs: 6, sm: 4, lg: 6 }, mb: 5 }}>
        {
          Smdata.map((a, i) => {
            return (
              <Box key={i}>
                <SocialIcon {...a} index={i} />
              </Box>
            )
          })
        }
      </Box>
      {/* <AdsComponent dataAdSlot='8012520338' /> */}
    </Container>
  )
}

export default WebImages

function ImageView({ media }) {
  return (
    <Box sx={{ borderRadius: '20px', alignItems: 'center', width: { xs: "100%", sm: "100%" }, top: 0, mx: 0.5 }}>
      <img src={media} width='100%' style={{ borderRadius: '20px', objectFit: 'cover' }} alt="scroll image" />
    </Box>
  )
}

function TextView({ value }) {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Outfit', textAlign: 'justify', fontSize: { xs: '12px', sm: '14px' } }}> {value} </Typography>
    </Box>
  );
}

function SocialIcon({ mi, index ,href }) {
  return (
    <Box sx={{ px: { xs: index % 2 !== 0 ? 2 : "0", md: index % 2 !== 0 ? 8 : "0" }, }} >
      <a href={href} target="_blank" rel="redirect to given link">
        <img src={mi} width="100%" alt="Social Media Icon Image" />
      </a>
    </Box>
  )
}
