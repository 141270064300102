import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import gp from "../../images/gps.svg"
import as from "../../images/aps.svg"
import React from 'react'

    const Dialogbox = ({opens,onClose,url}) => {
    const navigator = () => {
        window.open(url);
        onClose(true);
    }
    return (
        <Dialog open={opens} onClose={onClose} 
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(197, 255, 245,0.9)',
                    borderRadius: "12px",
                    mx: 0
                },
            }}
        >
            <DialogContent>
                <Box sx={{ p: { xs: 0, sm: 5 }, width: { xs: '230px', sm: 'auto' }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Box sx={{ mb: 5, fontWeight: 'bold' }}>
                        <Typography sx={{ fontSize: "27px", lineHeight: 2.5, textAlign: 'center' }}>Download the App</Typography>
                        <Typography sx={{ fontSize: '18px', }}>Avalible for iOS & Android</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box width='155px' onClick={() => { navigator(url = "https://play.google.com/store/apps/details?id=com.karmometer.android") }}> <img src={gp} width='100%' alt="" /></Box>
                        <Box sx={{ ml: { sm: 2 }, mt: { xs: 2, sm: 0 } }} width='150px' onClick={() => { navigator(url = "https://apps.apple.com/us/app/karmometer/id1616314036") }}> <img src={as} width='100%' height='100%' alt="" /></Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default Dialogbox
