import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Drawer, } from '@mui/material';
import Dialogbox from './Dialogbox/Dialogbox';
import LogoImage from '../images/logo51.png';
import AppBar from '@mui/material/AppBar';
import icon from '../images/Frame 4.png';
import Box from '@mui/material/Box';


    const Header = () => {    
    const textdata = [{value: 'FAQ',href: "faq",closed: false},{value: 'Download',closed: true},{value: 'Privacy Policy', href: "privacy-policy",closed: false},];
    const [open, setOpen] = useState(false);
    const drawerRef = useRef(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                handleClose();
            }
        };
        if (open) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [open]);

    return (
        <Box>
            <AppBar
                sx={{
                    backgroundColor: open ? 'rgba(110,184,183,0.6)' : 'rgb(197,255,245)',
                    // backdropFilter: open ? 'blur(10px)' : 'none',
                    boxShadow: 'none',
                    py: 3
                }}
            >
                <Container sx={{ maxWidth: { xs: 'xs', sm: 'sm', } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                        <Box sx={{ width: { xs: '185px', sm: 'auto' } }}>
                            <img src={LogoImage} alt="Logo" width="100%" style={{ maxHeight: '35px' }} />
                        </Box>
                        <Box sx={{ width: { xs: '25px', sm: '45px' } }}>
                            <img src={icon}onClick={handleOpen}width="100%" alt="icon Image" style={{cursor:"po"}}/>
                        </Box>
                    </Box>
                    <Drawer
                        anchor="top"
                        open={open}
                        onClose={handleClose}
                        variant="persistent"
                        ref={drawerRef}
                        PaperProps={{
                            sx: {
                                background: "rgba(110,184,183,0.6)",
                                backdropFilter: open ? 'blur(10px)' : 'none',
                                height: '250px',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: { xs: 9,md:10.5 },
                                py: {md:1}
                            }
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', color: '#fff' }} onClick={handleClose} >
                            { textdata.map((a, i) => (
                                <Box key={i}>
                                    <MenubarText {...a} index={i} />
                                </Box>
                            ))}
                        </Box>
                    </Drawer>
                </Container>
            </AppBar>
        </Box>
    );
};


export default Header;

function MenubarText({ value, href, closed, index }) {

    const [dialogOpen, setDialogOpen] = useState(false);
    const handleClick = () => {
        if (index == 1) {
            setDialogOpen(true);
        } else if (!closed) {
            window.open(href);
        }
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: '5px',
                    height: '42px',
                    width: { xs: "260px", sm: "300px" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    backgroundColor: '#008c8c',
                    my: 1
                }}
                onClick={handleClick}
            >
                <Button sx={{ fontWeight: '700', fontSize: '21px', color: '#fff' }} >{value}</Button>
            </Box>
          <Box>
            <Dialogbox opens={dialogOpen} onClose={handleCloseDialog}/>
          </Box>
        </>
    );
}

