import { ExpandMore } from '@mui/icons-material';
import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react'
import faqi from './../images/download.png'
import { db } from '../firebase'
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore'; // Import Firestore functions correctly
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

function Faq() {
    const [expanded, setExpanded] = useState(false);
    const [data, setData] = useState([]);
    const coll = collection(db, "FAQ_controller");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getData = async () => {
        try {
            const fetchedData = [];
            const q = query(coll, orderBy("created_at"), limit(100)); // Correct Firestore query syntax
            const querySnapshot = await getDocs(q); // Use the correct Firestore function

            querySnapshot.forEach((doc) => {
                const status = doc.data().status;
                const created_at = doc.data().created_at;

                if (status === 1) { // Correct the condition
                    const question = doc.data().question;
                    const answer = doc.data().answer;
                    fetchedData.push({ question, answer });
                }
            });

            setData(fetchedData);
        } catch (error) {
            console.error("Error getting data from Firebase:", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Box sx={{ mt: 12 }}>
                <Container sx={{ maxWidth: { xs: 'xs', sm: 'md' } }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box>
                            <Typography sx={{ fontSize: '22px', color: '#008C8C' }}>Frequently Asked Questions</Typography>
                        </Box>
                        <Box sx={{ height: '40px', width: '40px', ml: 2, mt: 0, justifyContent: 'center' }}>
                            <img src={faqi} height='100%' width='100%' alt="" />
                        </Box>
                    </Box>
                    {data?.map((a, i) => {
                        return (
                            <Box key={i} >
                                <AccordionView key={i} data={a} handleChange={handleChange} expanded={expanded} panel={i + 1} />
                            </Box>
                        );
                    })}
                </Container>  
            </Box>
        </>
    )
}

export default Faq

function AccordionView({ data, expanded, panel, handleChange }) {
    return (
        <Box sx={{ mt: 2 }}>
            <Accordion expanded={expanded === panel} onChange={handleChange(panel)} 
            style={{ borderRadius: '15px', border: '1px solid #fff' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={{ color: 'text.secondary', fontWeight: '600', fontSize: '21px' }}>{data.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontWeight: '400', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: data.answer?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
