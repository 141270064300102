import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import Header from './Header'
import WebImages from './WebImages'
import Advertisement from './Advertisement'

function Home() {
    return (
        <Box className="box">
            <Box>
                <Header />
                <WebImages />
                {/* <Advertisement /> */}
            </Box>
        </Box>
    )
}
export default Home
