import { Box } from '@mui/material';
import FAQ from './Pages/Faq';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';

function App() {
    return (
        <>
            <Box >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/faq" element={<FAQ />} />
                    
                    {/* <Route path="/download" element={<Download />} /> */}
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </Box>
        </>
    );
}

export default App;
